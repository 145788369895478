import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./styles.css";

// Import your images at the top of the file
import logoImage from "./a1 (1) 1.png";
import menuImage from "./Component 2.png";
import twitterImage from "./Socials.png";
import discordImage from "./Vector.png";
import walletImage from "./wallet.png";

const Navbar = ({ setShowRoadmap }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    console.log("showDropdown", showDropdown);
  }, [showDropdown]);

  return (
    <nav>
      <div className="logo">
        <a href="/">
          <img
          alt="y5"
            src={logoImage}
            style={{ objectFit: "cover", maxWidth: 541, maxHeight: 95 }}
          />
        </a>
      </div>
      <div className="buttons-container">
        <div className="icons">
        
        {/* eslint-disable-next-line */}
          <a href="#">
            <div className="dropdown">
              <button
                className="dropdown-button"
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                <img src={menuImage} alt="Menu" />
              </button>
              <div
                className={`dropdown-content ${
                  showDropdown ? "show-dropdown-content" : null
                }`}
              >
        {/* eslint-disable-next-line */}
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={() => setShowRoadmap((prev) => !prev)}
                >
                  Roadmap
                </a>{" "}
                <br />
                <a
                  href="https://celestial-junction.gitbook.io/celestial-junction/"
                  target="_blank"
                  rel="noreferrer"
                  className="dropdown-item"
                >
                  Whitepaper
                </a>{" "}
                <br />
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSe4Q31nmHH_tKA51valDJwzbbFDQkfI2Z49Da_U6rMeR2nIsw/viewform?usp=sf_link"
                  className="dropdown-item"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>{" "}
                <br />
              </div>
            </div>
          </a>
          <a href="https://twitter.com/CelestialJNFT" rel="noreferrer" target="_blank">
            <img src={twitterImage} alt="Twitter" />
          </a>
          <a href="https://discord.gg/ED2PyBn79T" rel="noreferrer" target="_blank">
            <img src={discordImage} alt="Discord" />
          </a>
        </div>
        <div style={{ marginRight: "5%" }}>
          <button
            className="wallet-button"
            onClick={() =>
              Swal.fire({
                icon: "info",
                title: "Soon!",
                background: "#1D2948",
                color: "#fff",
              })
            }
          >
            Connect Wallet{" "}
            <img src={walletImage} alt="Wallet" className="wallet-image" />{" "}
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
