import React, { useState } from 'react';

// Images:
import backgroundImage from './images/background-image.png';
import c1Image from './images/c1.png';
import c2Image from './images/c1-2.png';
import roadmapImage from './images/roadmap.png';
import Navbar from './components/Navbar/Navbar';

function App() {
  const [isC1Shown, setIsC1Shown] = useState(true);
  const [showRoadmap, setShowRoadmap] = useState(false);
  const [isC1Zoomed, setIsC1Zoomed] = useState(false);

  const backgroundStyle = {
    backgroundImage: `url(${showRoadmap ? roadmapImage : backgroundImage})`,
    backgroundSize: showRoadmap?'contain':'cover',
    backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    width: '100vw',
    transition: 'background 1s ease-in-out',
    zIndex: showRoadmap ? 9999 : 1,
    backgroundColor: '#1D2948'
  };

  const centerChildrens = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    width: '100vw',
    position: 'relative',
    transition: 'transform 0.5s ease-in-out',
  };

  const imageStyle1 = {
    position: 'absolute',
    maxWidth: '60vw',
    maxHeight: '60vh',
    marginTop:'5%',
    transition: 'all 0.5s ease-in-out',
    transform: isC1Zoomed ? 'scale(1.5)' : 'scale(1)',
    opacity: isC1Zoomed ? 0 : isC1Shown ? 1 : 0,
  };

  const imageStyle2 = {
    position: 'absolute',
    maxWidth: '60vw',
    maxHeight: '60vh',
    marginTop:'5%',
    marginRight:'5px',
    transition: 'all 0.5s ease-in-out',
    transform: isC1Zoomed ? 'scale(1.5)' : 'scale(0.5)',
    opacity: isC1Zoomed ? 1 : isC1Shown ? 0 : 1,
  };


  const handleRoadmapVisibility = () => {
    if (showRoadmap) {
      setShowRoadmap(false);
    }
  };

  const handleImage1Click = () => {
    setIsC1Zoomed(!isC1Zoomed);
  };

  const handleImage1TransitionEnd = () => {
    if (!isC1Zoomed && !isC1Shown) {
      setIsC1Shown(true);

    }
  };

  return (
    <div style={backgroundStyle} onClick={handleRoadmapVisibility} className='yo'>
      <div style={{ display: showRoadmap ? 'none' : 'flex' }}>
        <Navbar setShowRoadmap={setShowRoadmap} />
        <button onClick={() => setShowRoadmap((prev) => !prev)}>roadmap</button>
      </div>

      <div
        style={{ ...centerChildrens, display: showRoadmap ? 'none' : 'flex' }}
        onClick={handleImage1Click}
      >
        <img
        alt='y1'
          src={c1Image}
          style={{ ...imageStyle1 }}
          onTransitionEnd={handleImage1TransitionEnd}
        />
        <img src={c2Image} alt='y2' style={{ ...imageStyle2 }} />
      </div>
    </div>
  );
}

export default App;
